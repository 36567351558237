// import { createTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { mainColor } from "../../Theme/Theme";
import { createTheme } from "@mui/material/styles";
// import { createTheme } from "@mui/material";

export const useCardStyle = makeStyles((theme) =>
  createStyles({
    root: {
      transition: "0.5s ease",
      "&:hover": {
        transform: "scale(1.05, 1.05)",
      },
      //   overflow: "hidden",
      //   backgroundColor: theme.palette.background.paper,
    },
  })
);
//原本主題是暗底，
export const lightCardTheme = createTheme({
  palette: {
    mode: "light",
    primary: { main: mainColor },
    text: {
      primary: "#30302F",
      secondary: "#918F8D",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the styleSheet
        root: {
          // marginTop: 6,
          // marginBottom: 6,
          // Name of the rule
          fontWeight: 400,
          borderRadius: 4,
          border: 0,
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            color: "#fff",
            backgroundColor: "transparent",
          },
          textTransform: "none",
        },

        contained: {
          backgroundColor: "#ddd",
          color: "#444",
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
