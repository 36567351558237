import * as React from "react";
import { Box, BoxProps } from "@mui/material";
export default function CenterBox(props: BoxProps) {
  var style: React.CSSProperties = {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };
  Object.assign(style, props.style);

  return <div style={style}>{props.children}</div>;
  // const style = props.style;
  // style?.textAlign = "center";
  // return (
  //   <Box
  //     // style={{ textAlign: "center" }}
  //     // container

  //     display="flex"
  //     alignItems="center"
  //     justifyContent="center"
  //     // direction="column"
  //     // spacing={1}
  //     {...props}
  //   >
  //     {props.children}
  //   </Box>
  // );
}
