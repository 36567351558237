// import router from "next/router";
import * as React from "react";
import Head from "next/head";
export default function MetaTags({
  title,
  description,
  locale,
  imgURL,
  relativePath,
}: {
  title: string;
  description: string;
  locale: string;
  imgURL?: string;
  relativePath: string;
}) {
  const urlPath =
    locale == "en-US"
      ? "https://shop.redcandlegames.com" + relativePath
      : "https://shop.redcandlegames.com/" + locale + relativePath;
  return (
    <Head>
      {/* <title>{locale == "zh-TW" ? zhTitle : enTitle}</title> */}
      <title>{title}</title>
      <link rel="alternate" hrefLang={locale} href={urlPath} />
      {/* <meta
        name="google-site-verification"
        content="0lR5tDJg2ZEOSS9nvCyw51ti9kLOELNB3bkbBuQ2kx0"
      /> */}
      <link rel="canonical" href={urlPath} />
      <link
        rel="alternate"
        hrefLang={"zh-hant-cn"}
        href={"https://shop.redcandlegames.com/zh-CN" + relativePath}
      />
      <link
        rel="alternate"
        hrefLang={"zh-hant-tw"}
        href={"https://shop.redcandlegames.com/zh-TW" + relativePath}
      />
      <link
        rel="alternate"
        hrefLang={"en-us"}
        href={"https://shop.redcandlegames.com" + relativePath}
      />
      <link
        rel="alternate"
        hrefLang={"ja-jp"}
        href={"https://shop.redcandlegames.com/ja-JP" + relativePath}
      />
      <link
        rel="alternate"
        hrefLang={"ko-kr"}
        href={"https://shop.redcandlegames.com/ko-KR" + relativePath}
      />

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta
        key="viewport"
        name="viewport"
        content="initial-scale=1.0, width=device-width"
      ></meta>
      <meta
        name="keywords"
        content="赤燭遊戲,Red Candle Games,RedCandleGames,九日,NineSols,返校,Detention,還願,Devotion 下載 購買 buy download"
      ></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={title}></meta>
      {imgURL && <meta property="og:image" content={imgURL}></meta>}
      <meta property="og:url" content={urlPath}></meta>

      <meta property="og:site_name" content="Red Candle Games E-Shop"></meta>
      <meta name="description" content={description}></meta>
      <meta property="og:description" content={description}></meta>
      <meta property="fb:app_id" content="1108842882812885"></meta>
    </Head>
  );
}
