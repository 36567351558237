import {
  RichTextTitleImageStoryblok,
  VideoBlokStoryblok,
} from "functions/src/shared";
import {
  MARK_LINK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer";
import CenterBox from "../../Utility/CenterBox";
import { FillDivSVGText } from "../../Utility/FillDivSVGText";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Video } from "next-seo/lib/types";
import { CSSProperties } from "react";
import LazyLoad from "react-lazyload";
import { List, ListProps, Typography, TypographyProps } from "@mui/material";
import Link from "next/link";
export function StoryblokRichText({
  document,
  typographyProps,
  listProps,
}: {
  document: any;
  typographyProps?: TypographyProps;
  listProps?: ListProps;
}) {
  // document is the rich text object you receive from Storyblok,
  // in the form { type: "doc", content: [ ... ] }
  return (
    <div className="richText">
      {render(document, {
        markResolvers: {
          [MARK_LINK]: (children, { href, target, linktype }) => {
            if (linktype === "email") {
              // Email links: add `mailto:` scheme and map to <a>
              return <a href={`mailto:${href}`}>{children}</a>;
            }
            if (href.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <a href={href} target={target}>
                  {children}
                </a>
              );
            } else
              return (
                <Link href={href} passHref>
                  <a target={target}>{children}</a>
                </Link>
              );
          },
        },
        nodeResolvers: {
          [NODE_IMAGE]: (children, props) => {
            const { src, ...others } = props;
            if (src.endsWith(".webp"))
              return <LazyLoadImage src={src} {...others} />;
            else return <LazyLoadImage src={src + "/m/"} {...others} />;
          },
          [NODE_PARAGRAPH]: (children) => {
            var defaultStyle: React.CSSProperties = {
              marginBottom: 12,
              lineHeight: 2,
            };
            if (typographyProps) {
              const { style, ...other } = typographyProps;
              Object.assign(defaultStyle, style);
            }

            return (
              <Typography style={defaultStyle} {...typographyProps}>
                {children}
              </Typography>
            );
          },

          // [NODE_UL]: (children) => {
          //   return <List {...listProps}>{children}</List>;
          // },
        },
        blokResolvers: {
          ["VideoBlok"]: (b) => {
            const blok = b as unknown as VideoBlokStoryblok;
            const url = blok.video.filename.replace(
              "a.storyblok.com",
              "s3.amazonaws.com/a.storyblok.com"
            );
            return (
              <CenterBox>
                <LazyLoad>
                  <video
                    crossOrigin="anonymous"
                    style={{
                      maxWidth: "100%",
                      width: "auto",
                      paddingBottom: 16,
                    }}
                    autoPlay
                    loop
                    playsInline
                    muted
                  >
                    <source src={url} type="video/mp4"></source>
                  </video>
                </LazyLoad>
              </CenterBox>
            );
          },
          ["YoutubeBlok"]: (b) => {
            const blok = b as unknown as any; // as YoutubeBlok;

            return (
              <iframe
                style={{
                  // position: "absolute",
                  // top: 0,
                  // left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={`https://www.youtube.com/embed/${blok.youtubeId}`}
                frameBorder="0"
              />
            );
          },
          // ["RichTextTitleImage"]: (b) => {
          //   const blok = b as unknown as RichTextTitleImageStoryblok;
          //   return (
          //     <div
          //       // id={blok.title}
          //       style={{
          //         position: "relative",
          //       }}
          //     >
          //       <a
          //         id={blok.title}
          //         style={{
          //           position: "relative",
          //           top: -128,
          //           visibility: "hidden",
          //         }}
          //       ></a>
          //       <img
          //         style={{ width: "100%" }}
          //         src={blok.image.filename + "/m/"}
          //       ></img>
          //       <CenterBox
          //         style={{
          //           textAlign: blok.paddingLeft ? "left" : "center",
          //           color: blok.color.color,
          //           pointerEvents: "none",
          //           top: 0,
          //           position: "absolute",
          //           width: "100%",
          //           height: "100%",
          //           paddingLeft: blok.paddingLeft
          //             ? `${blok.paddingLeft}%`
          //             : null,
          //         }}
          //       >
          //         {/* 動態字體大小?? */}
          //         {/* <h4 style={{ color: "#000", fontSize: "2em" }}>
          //           {blok.title}
          //         </h4> */}
          //         {/* <FillDivSVGText fontSize={1} height={10}>
          //           {blok.title}
          //         </FillDivSVGText> */}

          //         <svg viewBox="0 0 500 30" width="100%">
          //           <foreignObject width="100%" height="100%">
          //             <text>{blok.title}</text>
          //           </foreignObject>
          //         </svg>

          //         {/* <svg viewBox="0 0 120 12" width={"100%"}>
          //           <foreignObject width="100%" height="100%">
          //             <text x="0" y="0">
          //               {blok.title}
          //             </text>
          //           </foreignObject>
          //         </svg> */}
          //       </CenterBox>
          //     </div>
          //   );
          // },
        },
      })}
    </div>
  );
}
