import * as React from "react";
import { Box, BoxProps } from "@mui/material";
interface RowProps extends BoxProps {
  alignHori?: "left" | "center" | "right";
  bottomRow?: boolean;
}
const alignMap = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
};
export default function Row(props: RowProps) {
  const { alignHori = "left", bottomRow = false, ...other } = props;
  let style: any = undefined;
  if (bottomRow === false) style = { ...props.style }; // marginBottom: 6,
  return (
    <Box
      display="flex"
      justifyContent={alignMap[alignHori]}
      alignItems="center" //stretch to fill top Bottom
      {...other}
      style={style}
    >
      {props.children}
    </Box>
  );
}
