import { Button, TextField } from "@mui/material";
import React from "react";
import { useAlertContext } from "../Alert/SnackBarProvider";
import AdminCheck from "./AdminCheck";
import { useAuthState } from "react-firebase-hooks/auth";
import AuthCheckLoading from "../Utility/Route/AuthCheckLoading";

function AdminRevalidateCurrentPageButton() {
  const alertCtx = useAlertContext();
  const [secret, setSecret] = React.useState("");
  const handleRevalidate = () => {
    // Call the API to revalidate the current page
    // Replace 'api/revalidate' with the actual API endpoint
    const _path = window.location.pathname;
    const allLang = false;
    var path = `/api/revalidate/?secret=${secret}&path=${_path}&all_lang=${allLang}`;
    console.log("final _path", _path);

    fetch(path)
      .then((response) => {
        var result = response;
        if (result.status === 200) {
          alertCtx.show(result.statusText, "success");
        } else if (result.status === 401) {
          alertCtx.show(result.statusText, "error");
        } else if (result.status === 500) {
          alertCtx.show(result.statusText, "error");
        }
        // Handle the response
        if (response.ok) {
          // Revalidation successful
          console.log("Page revalidated successfully");
        } else {
          // Revalidation failed
          console.error("Failed to revalidate page");
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error("An error occurred while revalidating page:", error);
      });
  };
  //FIXME: 不要做auth redirect, 只是不顯示
  //isadmin

  return (
    <AuthCheckLoading fallback={null}>
      <AdminCheck fallback={null}>
        <div
          style={{
            position: "fixed",
            bottom: "10px",
            left: "10px",
            flexDirection: "column",
            zIndex: 10000,
          }}
        >
          <TextField
            label="secret"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
          ></TextField>
          <Button onClick={handleRevalidate}>Revalidate Current Page</Button>
        </div>
      </AdminCheck>
    </AuthCheckLoading>
  );
}

export default AdminRevalidateCurrentPageButton;
