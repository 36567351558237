import {
  Card,
  CardActions,
  CardContent,
  Theme,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import AppleIcon from "@mui/icons-material/Apple";
import { FlexEmpty } from "../../Utility/FlexEmpty";
import Link from "next/link";
import * as React from "react";
import { useTranslation } from "react-i18next";
import TranslateWrapper from "../../i18n/TranslateWrapper";
import LazyLoad from "react-lazyload";
import { ProductStoryData } from "functions/src/shared";
import { ProductDownloadableStoryblok } from "functions/src/shared/Digital/StoryBlockSchema/components-schema";
import { StoryblokRichText } from "../../utils/Storyblok/RichText";
import { useLanguageContext } from "../../i18n/LanguageContext";
import {
  discountBackground,
  discountColor,
  mainColor,
  secondaryTextColor,
} from "../../Theme/Theme";
import { LinuxIcon } from "../../Utility/Icons/LinuxIcon";
import { WindowsIcon } from "../../Utility/Icons/WindowsLogo";
import { useCardStyle, lightCardTheme } from "./CardStyle";
import mixpanel from "../../Firebase/mixpanel";
import DiscountLabel from "./DiscountLabel";
import { PriceFloor } from "functions/src/shared";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default function DigitalGameCard({
  productData,
  mode = "sale",
}: {
  productData: ProductStoryData;
  mode?: "sale" | "library";
}) {
  const product = productData.content;
  console.log("[Game Product]", productData);

  // const product = gameData.downloadable[0];

  const { t } = useTranslation("gamepage");
  const lngCtx = useLanguageContext();
  if (product.price == null) {
    return <div>Product No Price</div>;
  }
  const price = product.price[0][lngCtx.currency];
  // const price: PriceData =  && gameData.price[];
  const cardStyleClass = useCardStyle();
  const label = lngCtx.priceLabel;
  const downloadable = product.downloadable && product.downloadable[0];
  // if (downloadable == null) {
  //   return <div> {productData.slug} No Downloadable</div>;
  // }
  // if (
  //   productData.content.app_link == null ||
  //   productData.content.app_link.cached_url == null
  // ) {
  //   return <div> {productData.slug} No app link</div>;
  // }
  React.useEffect(() => {
    console.log("Product Card", productData);
  }, [productData]);

  //FIXME: 過度code
  const url =
    mode == "sale"
      ? productData.content.app_link
        ? productData.content.app_link.cached_url +
          "?product_slug=" +
          productData.slug
        : "/app/" + productData.slug
      : "/downloads/" + productData.slug;
  const discountPrice = price * (1 - product.price[0].discountPercent / 100);
  const finalPrice = PriceFloor(discountPrice, lngCtx.currency);
  return (
    <TranslateWrapper ns="gamepage">
      <div id={productData.slug + "_GameCard"}>
        <LazyLoad>
          {/* <StyledEngineProvider injectFirst> */}
          {/* <ThemeProvider theme={lightCardTheme}> */}

          <div
            className={mode == "sale" ? cardStyleClass.root : ""}
            style={{ width: 300, position: "relative" }}
          >
            <Link href={url ? url : ""}>
              <a
                style={{ textDecoration: "none" }}
                onClick={() => {
                  mixpanel.track("click card", {
                    // type: gameData.category,
                    uid: productData.slug,
                  });
                }}
              >
                <Card
                  elevation={2}
                  style={{
                    width: 300,

                    borderRadius: 0,
                    // backgroundColor: "#F2EEEB",
                  }}
                >
                  {/* <CardActionArea> */}

                  {/* <LazyLoadImage
                    style={{ width: 300, height: 250 }}
                    src={gameData.card_img.url}
                  ></LazyLoadImage> */}
                  <div style={{ marginBottom: 5 }}>
                    <div
                      style={{
                        width: 300,
                        height: 250,
                        backgroundImage:
                          "url('" +
                          product.card_img.filename +
                          "/m/300x250" +
                          "')",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <Typography
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          background: discountBackground,
                          color: discountColor,
                          width: "auto",
                          display: "inline-block",
                          fontWeight: "bold",
                          boxShadow: "5px 5px 12px -5px rgba(0,0,0,0.66)",
                          fontSize: 18,
                        }}
                      >
                        {product.price[0].discountPercent &&
                          product.price[0].discountPercent > 0 &&
                          product.price[0].discountPercent + "% OFF"}
                      </Typography>
                    </div>
                  </div>
                  {/* {product.price[0].discountPercent != 0 && ( */}
                  <DiscountLabel priceData={product.price[0]}></DiscountLabel>
                  {/* )} */}

                  {mode == "sale" && (
                    <React.Fragment>
                      <CardContent
                        style={{
                          padding: 11,
                          minHeight: 80,
                          paddingBottom: 0,
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            fontFamily: "Raleway",
                          }}
                        >
                          {product.title}
                        </Typography>
                        {mode == "sale" && (
                          <div
                            style={{
                              color: secondaryTextColor,
                              fontSize: 14,
                              paddingTop: 10,
                            }}
                          >
                            <StoryblokRichText
                              document={product.description}
                            ></StoryblokRichText>
                          </div>
                        )}
                      </CardContent>
                      <CardActions
                        style={{
                          padding: 16,
                          paddingTop: 0,
                          color: secondaryTextColor,
                        }}
                      >
                        {downloadable?.windows_support && (
                          <WindowsIcon></WindowsIcon>
                        )}
                        {downloadable?.mac_support && <AppleIcon></AppleIcon>}
                        {downloadable?.linux_support && <LinuxIcon></LinuxIcon>}

                        <FlexEmpty></FlexEmpty>

                        {/* <Typography
                          style={{
                            fontSize: 20,
                          }}
                          color="textPrimary"
                        >
                          {label + " " + finalPrice}
                        </Typography> */}
                      </CardActions>
                    </React.Fragment>
                  )}
                </Card>
              </a>
            </Link>
            {/* <div className="overlay">
                
              </div> */}
          </div>
          {/* </ThemeProvider> */}
          {/* </StyledEngineProvider> */}
        </LazyLoad>
      </div>
    </TranslateWrapper>
  );
}
