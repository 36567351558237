import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const WindowsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 600 476.6">
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <g>
          <polygon points="0,80 0,240 224,240 224,52 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="256,48 256,240 512,240 512,16 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="256,272 256,464 512,496 512,272 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="0,272 0,432 224,460 224,272 		" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </SvgIcon>
);
