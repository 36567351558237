import { CircularProgress, Typography } from "@mui/material";
import "firebase/app";
import "firebase/auth";
// import AuthCheckRedirect from "../Utility/Route/AuthCheckRedirect";
import dynamic from "next/dynamic";
import * as React from "react";
import { auth } from "../Firebase/SDK/firebaseAuth";
import { useUserContext } from "../User/Auth/UserContext";
const AuthCheckRedirect = dynamic(
  () => import("../Utility/Route/AuthCheckRedirect"),
  {
    ssr: false,
  }
);
export default function AdminCheck(
  props: React.PropsWithChildren<{ fallback?: React.ReactNode }>
) {
  //TODO: check user is admin

  const user = auth.currentUser;
  const userCtx = useUserContext();
  // console.log(auth);
  // console.log(auth.currentUser);
  const [isAdmin, setIsAdmin] = React.useState<boolean>();
  React.useEffect(() => {
    // console.log(auth);
    // console.log(user);
    if (user != null) {
      console.log("[User ID]", user.uid);

      // user.getIdToken(true).then((result) => {
      //   console.log("getIdToken", result);
      // });
      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims["admin"]) {
            // Show admin UI.
            setIsAdmin(true);
            userCtx.setIsAdmin(true);
            console.log("admin verified:passed");
          } else {
            // Show regular user UI.
            setIsAdmin(false);
            console.log("no admin");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);
  // if (isAdmin == true) return <div> {props.children}</div>;
  // else if (isAdmin == false) return <Redirect to="/"></Redirect>;
  // const userRef = useDatabase().ref("users").child(auth.currentUser?.uid!);
  // useDatabaseObjectData()
  // else

  return (
    <AuthCheckRedirect>
      {isAdmin == true && <div>{props.children}</div>}
      {isAdmin == false && props.fallback}
      {/* {isAdmin == false && props.fallback == null && (
        <div>
          {" "}
          <Typography color="primary">Not Admin</Typography>
        </div>
      )} */}
      {isAdmin == undefined && (
        <div>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </AuthCheckRedirect>
  );
}
