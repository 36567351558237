import { Typography } from "@mui/material";
import { PriceFloor, PriceStoryblok } from "functions/src/shared";
import * as React from "react";
import { useLanguageContext } from "../../i18n/LanguageContext";
import Row from "../../Utility/Row";
export default function DiscountLabel({
  priceData,
}: {
  priceData: PriceStoryblok;
}) {
  const lngCtx = useLanguageContext();
  const label = lngCtx.priceLabel;
  const price = priceData[lngCtx.currency];
  const discountPrice = price * (1 - priceData.discountPercent / 100);
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Row
        style={{
          // width: 100,
          justifyContent: "center",
          paddingLeft: 16,
          paddingRight: 16,
          // paddingBottom: 8,
          height: 38,
          position: "absolute",
          bottom: 5,
          backgroundColor: "#000",
        }}
      >
        {/* {price.price} */}

        {priceData.discountPercent != 0 && (
          <React.Fragment>
            <Typography
              style={{
                textDecoration: "line-through",
                fontSize: 14,
                color: "#D9798D",
                marginRight: 12,
              }}
            >
              {label} {price}
            </Typography>
          </React.Fragment>
        )}

        <Typography style={{ fontSize: 20, color: "#fff" }}>
          {label}
          {PriceFloor(discountPrice, lngCtx.currency)}
        </Typography>
      </Row>
    </div>
  );
}
