import mixpanel from "mixpanel-browser";
import { config } from "../clientConfig";
if (config.payment.serverMode == "prod")
  mixpanel.init("f24deb33522a8c80620f8b3a3ab5b89f");
else {
  mixpanel.init("1e29c94e503cd9c68577a5350b7f4d86");
  console.log("dev mixpanel");
}

export default mixpanel;
