import * as React from "react";
import dynamic from "next/dynamic";
//TODO: 可能要加上User data也過才能過\
const AuthCheckLoadingInside = dynamic(
  () => import("./AuthCheckLoadingInside"),
  {
    ssr: false,
  }
);
export default function AuthCheckLoading(props: {
  // loadingNode?: React.ReactNode;
  fallback: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <AuthCheckLoadingInside {...props}>{props.children}</AuthCheckLoadingInside>
  );
}
