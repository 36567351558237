import * as React from "react";
import { Container, ContainerProps } from "@mui/material";
// import useTheme from "@mui/styles/useTheme";
import { useTheme } from "@mui/material/styles";
import AdminRevalidateCurrentPageButton from "../Admin/AdminRevalidateCurrentPageButton";
export function PageContainer(props: ContainerProps) {
  const theme = useTheme();
  const { style, maxWidth = "md", ...other } = props;
  //TODO: 怪怪的
  var defaultStyle: React.CSSProperties = {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 32,
    paddingBottom: 32,
  };

  // const { maxWidth = "md"} = props;
  defaultStyle = Object.assign(defaultStyle, style);
  // console.log("defaultStyle", defaultStyle);
  return (
    // <div  style={defaultStyle} {...other}>
    <Container
      className="page-container"
      style={defaultStyle}
      // style={{ padding: isMobileOnly && 0, ...props.style }}
      maxWidth={maxWidth}
      {...other}
    >
      {props.children}
      <AdminRevalidateCurrentPageButton></AdminRevalidateCurrentPageButton>
    </Container>
    // </div>
  );
}
